import React from "react";
import RightBanner from "../assets/img/banner-right-img.svg";
import LeftBanner from "../assets/img/banner-left-img.svg";
import loyalCard from "../assets/img/loyalcard.jpg";

const Banner = () => {
  return (
    // <section class="bg-black">
    //   <div class="d-flex justify-content-between align-items-center border border-white">
    //     <img class="" src={RightBanner} alt="" />

    //     <div className="">
    //       <h4 className="mt-3 text-secondary">
    //         <center>
    //           🎉 -20% Signing up bonus for new customers on first wash 🎉
    //         </center>
    //       </h4>
    //       {/* <div className="d-flex justify-content-center align-items-center">

    //       </div> */}
    //       <p className="text-secondary">
    //         <center>🆓 Every 10th wash free on any machine 🆓</center>
    //       </p>
    //       <p className="text-secondary">
    //         <center>
    //           {" "}
    //           💳 ⁠Spend $50 get $20 bonus credit on loyalty program to spend in
    //           store 💳
    //         </center>
    //       </p>
    //     </div>

    //     <img class="" src={LeftBanner} alt="" />
    //   </div>
    // </section>
    <div class="row my-4">
      <div class="col-md-6 p-0">
        <img
          src={loyalCard}
          alt="Person using a laundry payment kiosk"
          class="img-fluid h-10"
        />
      </div>
      <div class="col-md-6 d-flex flex-column justify-content-center p-5">
        <h1 class="text-custom fw-bold display-4">
          Sign up for our loyalty program
        </h1>
        <p class="mt-4 text-custom fw-bold">Receive the following rewards</p>
        <ul class="mt-4 text-custom">
          <li class="mb-2">Sign up bonus 30%</li>
          <li class="mb-2">Every 10th wash free 13.6kg $10 credit</li>
          <li class="mb-2">
            Spent $50 get $10 bonus credit on your loyalty program whether in
            parts or together.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Banner;
